import { Story } from '@/components'
import { Skeleton } from './Skeleton'
import { useGetDemandHistory } from '@/hooks/demands/queries/useGetDemandHistories'

export function History() {
  const { data, isLoading, isError } = useGetDemandHistory()

  if (isLoading || isError) {
    return <Skeleton isLoading={isLoading} />
  }

  return (
    <div className="flex size-full flex-col gap-4 overflow-auto">
      {data!.map((story) => (
        <Story key={`${story.userId}--${story.dateTime}`} {...story} />
      ))}
    </div>
  )
}
