import { getDemandHistory } from '@/services/modules/athenas/demands/get-demand-history'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const GET_DEMAND_HISTORIES_KEY = 'GET_DEMAND_HISTORIES'

export function makeGetDemandHistoriesKey(id: string) {
  return [GET_DEMAND_HISTORIES_KEY, { id }]
}

export function useGetDemandHistory() {
  const { id } = useParams()

  const query = useQuery({
    queryKey: makeGetDemandHistoriesKey(id!),
    queryFn: () => getDemandHistory(id!)
  })

  return query
}
