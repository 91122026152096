import * as RadioGroup from '@radix-ui/react-radio-group'
import { twMerge } from '@/styles/tailwindMergeConfig'

type Options = 'responsibles' | 'revision-time'

type TabOptions = {
  option: Options
  setOption: (value: Options) => void
}

export function TabOptions({ option, setOption }: TabOptions) {
  return (
    <RadioGroup.Root
      defaultValue="left"
      aria-label="options"
      className="flex w-full gap-2"
      onValueChange={(value: Options) => setOption(value)}
      value={option}
    >
      <RadioGroup.Item
        className={twMerge(
          'data-[state="checked"]:activeToggle',
          'data-[state="unchecked"]:disabledToggle'
        )}
        value="responsibles"
        aria-label="responsibles"
      >
        <span>Responsáveis</span>
      </RadioGroup.Item>
      <RadioGroup.Item
        className={twMerge(
          'data-[state="checked"]:activeToggle',
          'data-[state="unchecked"]:disabledToggle'
        )}
        value="revision-time"
        aria-label="revision-time"
      >
        <span>Seu tempo de revisão</span>
      </RadioGroup.Item>
    </RadioGroup.Root>
  )
}
