import * as RadioGroup from '@radix-ui/react-radio-group'
import { twMerge } from '@/styles/tailwindMergeConfig'

type TabOptions = {
  option: 'links' | 'history'
  setOption: (value: 'links' | 'history') => void
}

export function TabOptions({ option, setOption }: TabOptions) {
  return (
    <RadioGroup.Root
      defaultValue="left"
      aria-label="options"
      className="flex w-full gap-2"
      onValueChange={(value: 'links' | 'history') => setOption(value)}
      value={option}
    >
      <RadioGroup.Item
        className={twMerge(
          'w-20',
          'data-[state="checked"]:activeToggle',
          'data-[state="unchecked"]:disabledToggle'
        )}
        value="links"
        aria-label="links"
      >
        <span>Links</span>
      </RadioGroup.Item>
      <RadioGroup.Item
        className={twMerge(
          'w-20',
          'data-[state="checked"]:activeToggle',
          'data-[state="unchecked"]:disabledToggle'
        )}
        value="history"
        aria-label="history"
      >
        <span>Histórico</span>
      </RadioGroup.Item>
    </RadioGroup.Root>
  )
}
