import { getSignedUrl } from '@/services/modules/athenas/demands'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'

export function useDownload() {
  const mutation = useMutation({
    mutationKey: ['downloadFile'],
    mutationFn: async ({
      id,
      key,
      value
    }: {
      id: string
      key: string
      value: string
    }) => {
      const response = await getSignedUrl(id, key, value)

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = response.data
      anchorElement.click()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download'
      })
  })
  return mutation
}
