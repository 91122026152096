import { History } from '@/types/History'
import { Avatar } from '../Avatar'
import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'

export type StoryProps = History

export function Story({
  userId,
  message,
  auxiliaryMessage,
  dateTime
}: StoryProps) {
  const { data } = useGetUsers()

  const user = data?.find((user) => user.id === userId)

  const agent = user ? user.name : 'Not found'

  return (
    <section className="flex items-start gap-2 pl-4 text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
      <Avatar size="small" name={agent} />
      <div className="flex flex-col">
        <div>
          {message.map((part, i) => (
            <span className="font-mono text-labelMedium" key={(part.text, i)}>
              {part.fontStyle === 'bold' ? (
                <strong>{part.text} </strong>
              ) : (
                `${part.text} `
              )}
            </span>
          ))}
        </div>
        <div className="font-mono text-[10px] font-normal leading-4">
          <span>{dateTime}</span>{' '}
          {auxiliaryMessage.map((part, i) => (
            <span key={(part.text, i)}>
              {part.fontStyle === 'blue' ? (
                <span className="text-cromai-m3-sys-light-tertiary dark:text-cromai-m3-sys-dark-tertiary">
                  {part.text}{' '}
                </span>
              ) : (
                `${part.text} `
              )}
            </span>
          ))}
        </div>
      </div>
    </section>
  )
}
