import { Header } from './Header'
import { LoadingSkeleton } from './LoadingSkeleton'

import { useGetDemand } from '@/hooks/useGetDemand'
import { handleToast } from '@/utils/handleToast'

import { twMerge } from '../../styles/tailwindMergeConfig'
import { TabsLeft } from './TabsLeft'
import { TabsRight } from './TabsRight'

export function DemandsDetails() {
  const { data, isLoading, error } = useGetDemand({
    onError: () =>
      handleToast({
        message: 'Não foi possível carregar a página. Tente novamente.',
        type: 'error',
        autoClose: false
      })
  })

  if (isLoading || error) {
    return <LoadingSkeleton className={twMerge(isLoading && 'animate-pulse')} />
  }

  if (data) {
    return (
      <main className="flex size-full flex-col items-center overflow-auto bg-cromai-m3-sys-light-background px-7 pb-10 pt-6 font-mono dark:bg-cromai-m3-sys-dark-background lg:h-screen">
        <Header />
        <section
          className={twMerge(
            'lg:flex-row',
            'mt-6 flex h-full w-full max-w-screen-xl flex-col gap-6 '
          )}
        >
          <TabsLeft />
          <TabsRight />
        </section>
      </main>
    )
  }
}
