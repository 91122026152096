export function Skeleton({ isLoading }: { isLoading?: boolean }) {
  return (
    <div
      role="progressbar"
      className={`flex w-full flex-col gap-4 overflow-hidden ${isLoading && 'animate-pulse'}`}
    >
      {Array.from({ length: 7 }).map((_, i) => (
        <div key={`item-${i}`} className="flex w-full gap-2 pl-5">
          <div className="flex size-6 rounded-full bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
          <div className="flex h-10 w-2/3 flex-col gap-2">
            <div className="mt-1 h-3 max-w-60  rounded-sm bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
            <div className="h-2 max-w-40 rounded-sm bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
          </div>
        </div>
      ))}
    </div>
  )
}
